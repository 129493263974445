
  import Vue from 'vue';
  import * as api from '@/api';
  import messageSetFilter from './messageSetFilter.vue';

  export default Vue.extend({
    name: 'MessageSet',
    components: {
      messageSetFilter,
    },
    data() {
      return {
        messageList: [] as api.BroadcastMessages[],
        pagination: {
          totalCount: 0,
          currentPage: 1,
          pageSize: 10,
        },
        queryParams: {
          title: null,
          receiverType: null,
          startPublishTime: null,
          endPublishTime: null,
          sorting: 'createdAt DESC',
          skipCount: 0,
          maxResultCount: 10,
        },
        loading: false,
      };
    },
    watch: {
      queryParams: {
        async handler() {
          await this.fetchData();
        },
        deep: true,
      },
      pagination: {
        handler(pagination) {
          this.queryParams.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
          this.queryParams.maxResultCount = pagination.pageSize;
        },
        deep: true,
      },
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '消息设置',
          path: 'messageSet',
        },
      ]);
      await this.fetchData();
    },
    methods: {
      open(id) {
        this.$store.commit('messageId', id);
        this.$router.push({
          path: id ? '/operation/edit' : '/operation/create',
        });
      },
      changeInp(data) {
        Object.assign(this.queryParams, data);
      },
      handleSorting(val) {
        this.queryParams.sorting = val;
      },
      async fetchData() {
        this.loading = true;
        const res = await api.getBroadcastMessages(this.queryParams);
        this.loading = false;
        if (!api.error(res)) {
          this.pagination.totalCount = res.data.totalCount;
          this.messageList = res.data.items;
        }
      },
      async operation(item, send: boolean) {
         const res = send ? await api.putMessagesPublish({ id: item.messageId }) : await api.putMessagesWithdraw({ id: item.messageId });
        if (!api.error(res)) {
          this.$message.success(send ? '发布成功' : '撤回成功');
          if (send && item.receiverType === 1) this.$store.dispatch('getUnreadCount');
          this.fetchData();
        } else {
          this.$message.error(res.error.message);
        }
      },
    },
  });
