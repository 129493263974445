
  import Vue from 'vue';
  import moment from 'moment';

  export default Vue.extend({
    name: 'MessageFilter',
    props: {
      level: {
        type: Array,
      },
    },
    data() {
      return {
        date: '',
        queryParams: {
          title: null,
          receiverType: null,
          startPublishTime: null as string | null,
          endPublishTime: null as string | null,
        },
      };
    },
    watch: {
      date(val) {
        this.queryParams.startPublishTime = val ? moment(val[0]).format('YYYY/MM/DD') : null;
        this.queryParams.endPublishTime = val ? moment(val[1]).format('YYYY/MM/DD') : null;
      },
    },
    methods: {
      reset() {
        this.queryParams = {
          title: null,
          receiverType: null,
          startPublishTime: null,
          endPublishTime: null,
        };
        this.date = '';
        this.$emit('changeInp', JSON.parse(JSON.stringify(this.queryParams)));
      },
      submit() {
        this.$emit('changeInp', JSON.parse(JSON.stringify(this.queryParams)));
      },
    },
  });
